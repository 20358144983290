<template>
  <div class="applications">
    <h2 class="text-2xl mb-4 title-search">Rechercher une Application Thématique</h2>
    <div class="grid grid-cols-6 mb-4">
      <div class="search col-start-2 col-span-4 w-full max-w-7xl">
        <form @submit.prevent="goToPage(1);searchApplications(search, thematique, echelle)">
            <div class="searchForm">
              <div>
                <label class="block text-gray-700 text-sm font-bold mb-2 mt-4">
                  Mot(s) clef
                </label>
                <input v-model="search" @keydown.enter="searchApplications(search, thematique, echelle)" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text">
              </div>

              <div class="pb-4">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-4">
                  Thématique:
                </label>
                <div class="relative">
                  <select v-model="thematique" @keydown.enter="searchApplications(search, thematique, echelle)" class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                    <option value="" >Toutes les thématiques</option>
                    <option v-for="option in listThematiques" :key="option.value" :value="option.value">{{ option.label }}</option>
                  </select>
                  <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                  </div>
                </div>
              </div>

              <div class="pb-4">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-4">
                  Echelle:
                </label>
                <div class="relative">
                  <select v-model="echelle" @keydown.enter="searchApplications(search, thematique, echelle)" class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                    <option value="" >Toutes les échelles</option>
                    <option v-for="option in listEchelles" :key="option.value" :value="option.value">{{ option.label }}</option>
                  </select>
                  <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                  </div>
                </div>
              </div>
                <button class="btn-application" type="submit">Rechercher</button>
              <button class="btn-application" type="button" @click="resetForm">Réinitialiser</button>
          </div>
        </form>
      </div>
    </div>

    <div class = "loading" v-if="!loading">
      Chargement en cours ...
    </div>

    <div v-if="applications.length!==0">
      <div>
        <h2 class="text-2xl mb-3">{{applications.length}} résultats pour votre recherche</h2>
        <p class="mb-3">Trier par:
          <button class="btn-trie" type="button" @click="getApplicationsByCriteria('annee')">Année</button>
          <button class="btn-trie" type="button" @click="getApplicationsByCriteria('echelle')">Echelle</button>
        </p>
      </div>

      <div class="pagination">
        <button class="btn-pagination" type="button" @click="goToPage(1)">&laquo;</button>
        <button class="btn-pagination" type="button" @click="changePage(-1)" :disabled="currentPage === 1">Précédent</button>
        <button v-for="pageNumber in visiblePages" :key="pageNumber" @click="goToPage(pageNumber)" :class="{ 'btn-pagination': true, 'active': pageNumber === currentPage }">{{ pageNumber }}</button>
        <button class="btn-pagination" type="button" @click="changePage(1)" :disabled="currentPage === totalPages">Suivant</button>
        <button class="btn-pagination" type="button" @click="goToPage(totalPages)">&raquo;</button>
      </div>

      <div class="grid grid-cols-12">
        <div class="loadApplications col-start-2 col-span-10" v-bind:key ="application.idApplication" v-for="application in paginatedData">
          <Application :application="application"></Application>
        </div>
      </div>

        <div class="pagination">
          <button class="btn-pagination" type="button" @click="goToPage(1)">&laquo;</button>
          <button class="btn-pagination" type="button" @click="changePage(-1)" :disabled="currentPage === 1">Précédent</button>
          <button v-for="pageNumber in visiblePages" :key="pageNumber" @click="goToPage(pageNumber)" :class="{ 'btn-pagination': true, 'active': pageNumber === currentPage }">{{ pageNumber }}</button>
          <button class="btn-pagination" type="button" @click="changePage(1)" :disabled="currentPage === totalPages">Suivant</button>
          <button class="btn-pagination" type="button" @click="goToPage(totalPages)">&raquo;</button>
        </div>      
    </div>

    <div v-if="!resultApplication">
      <h2 class="text-2xl mb-3">Aucun résultat trouvé pour votre recherche</h2>
    </div>

  </div>
</template>

<script>
import Application from "@/components/Application.vue";
import {getAll} from "@/composition/afficherApplications";
import {listSelect} from "@/composition/listSelect";
import {usePagination} from "@/composition/pagination";

export default ({
  name: 'Applications',
  components:{
    Application
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup(){

    const resetForm = async function(){
      search.value ="";
      thematique.value ="";
      echelle.value="";
      applications.value = [];
    }

    const {
      listThematiques,
        listEchelles
    } = listSelect()

    const {
      thematique,
      echelle,
      loading,
      applications,
      resultApplication,
      search,
      searchApplications,
      getApplicationsByCriteria
    } = getAll()

    const{
      pageSize,
      currentPage,
      totalPages,
      paginatedData,
      changePage,
      goToPage,
      visiblePages,
    } = usePagination(applications)

    return{
      pageSize,
      currentPage,
      totalPages,
      paginatedData,
      changePage,
      goToPage,
      visiblePages,
      listThematiques,
      listEchelles,
      thematique,
      echelle,
      loading,
      applications,
      resultApplication,
      search,
      searchApplications,
      getApplicationsByCriteria,
      resetForm
    }
  }
})

</script>

<style>

.search{
  @apply text-left border-solid border-2;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.searchForm{
  @apply ml-6 mr-6 mb-2
}
.loadApplications{
  @apply text-left ml-24 mr-24 mb-5 bg-blue-400 text-black p-6 border-2;
  background-color: 	#d6dbdd;
}
.btn-application{
  @apply font-bold py-2 px-4 rounded-full bg-gray-400 text-white;
  margin: 1em;
}
.btn-application:hover {
  @apply bg-gray-500;
}
.btn-trie{
  @apply font-bold py-2 px-4 rounded-full bg-blue-900 text-white;
  margin: 1em;
}
.btn-trie:hover {
  @apply bg-blue-400;
}
.applications{
  @apply mt-1;
  min-height: 640px;
}
</style>
import {ref} from "vue";
import http from "@/http";


// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getAll(){
    const applications = ref([])
    const loading = ref(true)
    const thematique = ref ("")
    const echelle = ref("")
    const search = ref("")
    const resultApplication = ref(true)

    const getApplicationsByCriteria = async function(criteria){
        resultApplication.value = true
        http.getApplicationsByCriteria(criteria).then((data)=>{
            applications.value = data
            if (applications.value.length ===0){
                resultApplication.value = false
            }
        })
    }

    const searchApplications = async function(){
        resultApplication.value = true
        loading.value = false
            http.searchApplications(search.value, thematique.value, echelle.value).then((data)=>{
                applications.value = data
                if (applications.value.length ===0){
                    resultApplication.value = false
                }
            }).finally(()=>
                loading.value = true
            )
    }

    return{
        thematique,
        echelle,
        search,
        loading,
        applications,
        resultApplication,
        searchApplications,
        getApplicationsByCriteria
    }
}
<template>
  <div>
    <div>
      <div>
        <div>
          <p class="mb-0"><strong>{{application.titre}}</strong></p>
          <p class="mb-0 italic" v-if="application.validee === true">(Application validée par l'auteur)</p>
          <p class="mb-0" v-if="application.idApplication !== null">Application n°{{application.idApplication}}</p>
          <p class="mb-0" v-if="application.auteurs !== null">auteur(s): {{application.auteurs}}</p>
          <p class="mb-0" v-if="application.thematiques !== null">Thématique(s): {{application.thematiques}}</p>
          <p class="mb-0" v-if="application.annee !== null">Année de création: {{application.annee}}</p>
          <p class="mb-0" v-else>Année de création: non connue</p>
          <p class="mb-0" v-if="application.localisation !== null">Localisation: {{application.localisation}}</p>
          <p class="mb-0" v-if="application.resolutionSignification !== null">Echelle: {{application.resolutionSignification}}</p>
          <p class="mb-0" v-else>Echelle: non connue</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import {getAll} from "@/composition/afficherApplications";

export default {
  props:{
    application: Object
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup(){
    const {
      applications
    } = getAll()
    return {
      applications
    }
  }
}

</script>
<style>
</style>